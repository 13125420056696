$addUserSuggestionItemHeight: 35px;

.react-autosuggest__suggestions-list {
  list-style-type: none;
  padding-left: 0;
}

.addUserField {
  width: 100% !important;

  .userNameInput {
    width: calc(28% - 10px);
    margin-top: 1px;
    height: 37px;
    input {
      width: 100%;
      height: 100%;
      padding-left: 5px;
    }
  }

  .addUserSuggestionBox {
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: ($addUserSuggestionItemHeight * 5 + 2px);
    overflow-y: auto;

    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .addUserSelectItem {
      padding: 5px;
      cursor: pointer;
      height: $addUserSuggestionItemHeight;

      &.highlight {
        background-color: #eee;
        color: inherit !important;
      }
    }
  }

  > .input-group-prepend > .expand {
    height: 39px;
    margin-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px !important;
  }
}
