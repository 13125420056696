.SettingHistoryHeader {
  .card-body {
    padding: 15px 30px;
  }
}

.SettingHistoryCard {
  .MuiTableBody-root {
    > *:nth-child(2n) {
      background-color: #ebebeb;
    }
  }
}
