@keyframes test {
  from {
    transform: scale(0);
  }

  to {
    scale: scale(1);
  }
}

.selectorRadial {
  margin: auto;
  position: unset !important;

  svg {
    > g {
      > path {
        transform-origin: 50% 50%;
        animation: test 0.15s 0s 1;
      }

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }
}

.selector-card {
  text-align: center;
  padding-top: 10px;

  .clickable {
    display: unset!important;
    cursor: pointer;
  }

  .element-error {
    position: relative;
    height: 0;
    margin-top: 0;
  }
  
  .icon-container {
    color: #888;
  
    svg {
      height: 150px;
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
}

.form-field-marginless-y {
  margin-top: 0!important;
  margin-bottom: 0!important;

  > .form-control {
    margin-top: 0!important;
    margin-bottom: 0!important;
  }
}