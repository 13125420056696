.ArtificialIntelligenceSwitch {
  h3 {
    text-align: center;
  }

  label {
    text-align: center;
    font-size: 18px;
  }
}
