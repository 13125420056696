$themes: (
  light: (
    colorTopbar: #4caf50,
    colorTopbarItems: #ffffff,
    colorBackground: white,
    colorBackgroundBody: #f2f4f7,
    colorBackgroundAlternative: #f3f4f8,
    colorText: #646777,
    colorTextAdditional: #646777,
    logoImg: url(../../content/ArcAppIcon.svg),
    colorHover: rgba(255, 255, 255, 0.3),
    colorHoverNegative: rgba(0, 0, 0, 0.05),
    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #b4bfd0,
    colorFitness: #646777,
  ),
  dark: (
    colorTopbar: #4caf50,
    colorTopbarItems: #ffffff,
    colorBackground: #232329,
    colorBackgroundBody: #2a2a31,
    colorBackgroundAlternative: #f3f4f8,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    logoImg: url(../../content/ArcAppIcon.svg),
    colorHover: rgba(0, 0, 0, 0.3),
    colorHoverNegative: rgba(255, 255, 255, 0.05),
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$color-accent: #4caf50;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #444444;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #eecd4a;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;

$color-black: #030303;

$color-schedule-type-light: #ff851b;
$color-schedule-type-heating: #ff4136;
$color-schedule-type-door-lock: #116dbf;
$color-schedule-summary-interval: #4caf50;

$breakpoints: (
  max: (
    sm: 575.98px,
    md: 767.98px,
    lg: 991.98px,
    xl: 1199.98px,
  ),
  min: (
    xs: 0px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
  ),
);
@mixin minSize($size) {
  $minMap: map-get($breakpoints, "min");
  $minSize: map-get($minMap, $size);

  @media only screen and (min-width: $minSize) {
    @content;
  }
}
