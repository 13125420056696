.div-style {
  padding: 15px,
};

.div-wrapper {
  border: 1px solid #d6d8d9
};

.form-inside {
  padding: 10px
};

.leaf{
  color: green !important;
}
.consumption-cell-title{
  font-size: 22px;
}

.btn-custom{
  margin-bottom: 0;
  line-height: 16.3px;
}

.button-div{
  margin-bottom: 1rem !important;
}
