.infoModelSubmitButton {
  margin: 0 10px;
  height: calc(100% - 10px);
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .infoModelSubmitButton {
    width: 140px !important;
    margin-left: calc(100% - 300px) !important;
    margin-top: 5px;
  }
}
