@import "~rc-time-picker/assets/index.css";

.rc-time-picker {
  width: 100%;
  display: flex;

  .rc-time-picker-icon {
    width: 32px;
  }

  .rc-time-picker-input {
    border-radius: 0;
    width: calc(100% - 32px);
  }
}

.rc-time-picker-panel {
  padding-top: 3px;
}

.rc-time-picker-panel-input-wrap {
  display: none;
}

.rc-time-picker-panel-inner {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 0;
  margin-top: 32px;
}

.rc-time-picker-panel-select {
  overflow: hidden;
  border-right: 1px solid #eff1f5;

  @include themify($themes) {
    color: themed("colorTextNegative");
  }

  ul {
    padding-right: 30px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 86px;
    height: 144px;
  }

  li {
    padding: 0;
    text-align: center;
    transition: 0.3s;
    width: 56px;
  }
}

li.rc-time-picker-panel-select-option-selected {
  background: $color-blue;
  color: white;

  &:hover {
    background: $color-blue-hover;
  }
}

.rc-time-picker.rc-time-picker--icon {
  .rc-time-picker-input {
    border-radius: 5px 0 0 5px !important;
  }

  .form__form-group-icon {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    svg {
      display: block;
    }
  }
}
