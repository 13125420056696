.restrictionsHeaderCard {
  padding-top: 10px;
  padding-bottom: 10px;

  > .row {
    > .lnr {
      font-size: 40px;
      color: #4caf50;
    }

    h2 {
      margin-left: 15px;
      margin-top: 5px;
    }

    .expand {
      margin-left: auto;
      margin-bottom: 0px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
