.intervalsForm {
  .text-field {
    min-width: 180px;
    max-width: 250px;
  }

  .col {
    min-width: 200px;
    max-width: 270px;
  }

  .expand {
    height: calc(1.1875em + 30px);
    padding: 0 50px;
    margin-top: 15px;
    margin-bottom: 1rem;
    float: right;
  }
}

@media only screen and (min-width: 1520px) {
  .intervalsForm {
    .row {
      width: 85%;
      float: left;
    }
  }
}

@media only screen and (max-width: 900px) {
  .intervalsForm {
    .text-field,
    .col {
      width: 100%;
      flex-basis: unset;
      min-width: unset;
      max-width: unset;
    }
  }

  .intervalsEditButton {
    width: 100%;

    > .expand {
      height: 50px;
      max-width: 100%;
      width: 100%;
    }
  }
}
