.toggleButtonGroup {
  width: 140px;

  > label {
    width: 100%;
    text-align: center;
  }

  > div {
    width: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;

    > .toggle-btn {
      padding: 0;
      display: inline-block;
    }
  }
}
