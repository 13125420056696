.RDevNotificationsHeader {
  .card-body {
    padding: 15px 30px;
  }
}

.RDevNotificationsDateTimeRangeSelector {
  .card-body {
    padding-top: 5px;
    padding-bottom: 20px;
  }
}

.RDevNotificationsCard {
  .MuiTableBody-root {
    > *:nth-child(2n) {
      background-color: #ebebeb;
    }
  }
}
