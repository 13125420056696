.temperatureField {
  display: flex;

  @media only screen and (max-width: 1200px) {
    min-width: calc(50%);
  }
  @media only screen and (max-width: 768px) {
    min-width: calc(100%);
  }

  > div {
    width: 18px;

    > label {
      margin-top: 40px;
      width: 18px;
      display: inline-block;
    }
  }

  > .form-group {
    width: calc(100% - 18px);
    padding-left: 5px;
  }
}

.temperatureCardRow > .expand {
  margin-left: 15px;
  margin-right: 15px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
