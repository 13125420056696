@import "scss/settings/variable";

.react-datepicker__month-container {
  padding: 0 3px;
  
  .react-datepicker__header {
    border-bottom: unset;
    background-color: unset;
    position: unset;
    padding-top: unset;
  }

  .react-datepicker__day-names {
    padding-top: 1.7rem;
  }

  .react-datepicker__current-month {
    position: absolute;
    left: 0;
    right: 0;
  }

  .react-datepicker__day-names,
  .react-datepicker__current-month {
    border-bottom: 2px solid #dee2e6;

    .react-datepicker__day-name {
      font-weight: bold;
    }
  }
}

.react-datepicker__input-time-container {
  margin: 0 !important;
  height: 100%;

  .react-datepicker-time__input {
    margin: 0 !important;
    display: flex !important;

    .table-condensed {
      padding: 0 20px;
      display: grid;
      grid-template-columns: 2fr 1fr 2fr;
      grid-template-rows: 1fr 1fr 1fr;

      .hour-up {
        grid-column: 1;
        grid-row: 1;
      }

      .hour-value {
        grid-column: 1;
        grid-row: 2;
      }

      .hour-down {
        grid-column: 1;
        grid-row: 3;
      }

      .separator {
        grid-column: 2;
        grid-row: 2;
      }

      .minute-up {
        grid-column: 3;
        grid-row: 1;
      }

      .minute-value {
        grid-column: 3;
        grid-row: 2;
      }

      .minute-down {
        grid-column: 3;
        grid-row: 3;
      }

      * {
        display: flex !important;
        align-items: center;
        align-content: center;
        justify-content: center;
      }

      span.lnr {
        color: $color-blue;
        margin: 0;
        height: 50px;
        width: 50px;
        transition: background-color 0.1s ease-in-out;
        border-radius: 20rem;

        &:hover {
          background-color: #66666622;
        }
      }
    }
  }
}
