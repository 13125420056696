@import "../../scss/settings/variable.scss";

.lightingCard {
  .gauge-value-box {
    &:hover,
    *:hover {
      cursor: pointer;
    }
  }
}

.inflated-icon {
  &-off {
    color: $color-gray;
  }

  &-on {
    color: $color-accent;
  }
}

.initialized-icon {
  &-off {
    color: $color-gray;
  }

  &-on {
    color: $color-accent;
  }
}
