/*
    SWITCHBOX
*/
#switchBoxPartial {
  background-image: url("../../content/images/switchbox/silver-noise-2.png");
}

.switchbox {
  text-align: center;

  .text-value-card {
    border: 2px solid #4caf50!important;
    background-color: #4caf50!important;
    color: white;
  }
  
  .alert-info {
    background-color: #00c0ef!important;
    border: none!important;
    color: white;
  }
}

.switchbox-control {
  display: inline-block;
  margin: 30px 0;
}

.switchbox-control .txt-label {
  position: relative;
  border: 2px solid black;
  margin: 0 auto;
  padding: 3px 10px;
  background: white;
}

.btn-switchbox {
  width: 60px;
  height: 60px;
  margin: 10px auto;
  background-image: URL("../../content/images/switchbox/switchbox_switch-black-2.svg");
  background-size: contain;
}

.btn-switchbox.left {
  transform: rotate(-30deg);
  -ms-transform: rotate(-30deg); /* IE 9 */
  -webkit-transform: rotate(-30deg); /* Chrome, Safari, Opera */
}

.btn-switchbox.right {
  transform: rotate(30deg);
  -ms-transform: rotate(30deg); /* IE 9 */
  -webkit-transform: rotate(30deg); /* Chrome, Safari, Opera */
}

.btn-switchbox.dark-red {
  background-image: URL("../../content/images/switchbox/switchbox_switch-black-red-2.svg");
}

.btn-switchbox-press {
  display: block;
  width: 55px;
  height: 55px;
  margin: 10px auto;
  background: green;
  box-shadow: inset 0 0px 0px 3px rgba(255, 255, 255, 0.2), 0 0 5px black,
    inset -15px 20px 10px -10px rgba(0, 0, 0, 0.3);
  border: 2px solid black;
  border-radius: 50%;
}

.switchbox-light {
  display: block;
  width: 55px;
  height: 55px;
  margin: 10px auto;
  background: #eee;
  box-shadow: inset 0 0px 0px 5px rgba(0, 0, 0, 0.2), 0 0 5px black;
  border: 2px solid black;
  border-radius: 50%;
}

.switchbox-light.white {
  background: lightgrey;
}

.switchbox-light.on:before,
.switchbox-light.failure:before {
  content: "";
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
}

.switchbox-light.failure:before {
  box-shadow: 0 0 9px 16px red, 0 0 9px 20px darkred;
}

.switchbox-light.white.on:before {
  box-shadow: 0 0 9px 16px white, 0 0 9px 20px #eee;
}

.switchbox-light.dark-red {
  background: #c00000;
}

.switchbox-light.green.on:before {
  box-shadow: 0 0 9px 16px #40ff40, 0 0 9px 20px green;
}

.text-value-card {
  display: inline-block;
  border: 2px solid green;
  padding: 5px 25px;
  border-radius: 6px;
}

.switchbox-controller {
  width: 575px;
  margin: 50px auto 30px auto;
  background: white;
  border-radius: 26px;
  padding: 26px;
  text-align: left;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);

  > div {
    padding: 0;
  }

  table {
    width: 100%;
  }
}

.switchbox-controller img {
  object-fit: contain;
}

.switchbox-controller .list-container {
  overflow-y: auto;
  border: 3px solid #ddd;
}

.switchbox-controller table tr:hover {
  background: #efefef;
}

.switchbox-controller table tr td {
  padding: 2px 10px;
  border-bottom: 1px solid #666;
}

.switchbox-controller table tr td:last-child {
  padding-left: 0px;
  text-align: right;
}

.switchbox-controller table tr:last-child td {
  border-bottom: none;
}

#switchboxUpdated {
  margin: 7px 0 0 4px;
  color: grey;
}
