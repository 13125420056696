.status-box {
  .airdome-icon {
    margin-top: 10px;

    > * {
      min-height: 50px;
      display: inline-block;
    }
    img {
      max-width: 90px;
      max-height: 50px;
    }
  }

  .header {
    max-width: 50%;

    > * {
      word-wrap: break-word;
    }

    > h5 {
      margin-top: -8px;
    }
  }

  > .card {
    padding-left: 15px;
    padding-right: 15px;

    > .card-body > * {
      display: inline-block;
    }
  }

  > *,
  .card {
    padding-bottom: 15px;
  }

  .last-update {
    text-align: end;
    width: 100%;
  }
}

.temp-box > * > * > * {
  display: inline-block;
}

.status-gauge-box {
  padding-top: 0;
  padding-left: 3px;
  padding-right: 3px;

  > * {
    display: inline-block;
    > * {
      display: inline-block;
    }
  }
}

.ai-box {
  align-self: flex-end;
  margin-left: 10px;
  margin-right: 10px;
  max-width: calc(100% - 20px);

  .card__title {
    margin-top: 0;

    * {
      font-weight: 600;
    }
  }

  .toggle-btn,
  .toggle-btn__input-label {
    cursor: inherit !important;
  }

  img {
    object-fit: contain;
    max-height: 160px;
  }
}

.statistics-table {
  margin-top: 1rem;

  th {
    padding-left: 1rem;
    padding-top: 0.3rem;
  }
  td {
    padding: 0;
  }
}

.gauge-card {
  padding: 10px 12px;

  .card {
    padding-bottom: 5px !important;
  }

  .card-body {
    padding: 15px 0px 0px 0px;
  }
}

.gauge-value-box {
  margin: auto;
}

.airdome-country-flag {
  margin-top: 5px;
  height: 30px;
  width: auto;
  border: 1px solid black;
}

.gauge-value-box-chart-info {
  top: calc(50% - 70px) !important;
}

.refresh {
  align-self: flex-end;
}

@media only screen and (max-width: 991px) {
  .header {
    max-width: 100% !important;
    width: 100%;
  }

  .airdome-country-flag {
    margin-top: 20px;
  }

  .refresh {
    align-self: initial;
    width: 100%;
    margin-top: 4rem;
    margin-left: 0.5rem !important;
  }

  .status-box .last-update {
    text-align: start;
    margin-left: 0.5rem !important;
  }
}

.chart-container > h3 {
  text-align: center;
  color: #ff4861;
}