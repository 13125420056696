.circle-icon-green {
  color: greenyellow;
}

.circle-icon-orange {
  color: orange;
}

.circle-icon-red {
  color: red;
}

.circle-icon-black {
  color: black;
}

.airdome-circle-span {
  vertical-align: middle;
}

.airdome-circle-span svg {
  margin-top: -2px;
}

.airdome-country-span > * {
  margin: 0;
  display: inline-block;
  height: 20px;
  line-height: 20px;
}

.airdome-country-span > img {
  margin-top: -3px;
  width: auto;
  object-fit: fill;
  border: 1px solid black;
}

.material-table__cell > span > * {
  display: inline-block;
}

.airdome-name-p {
  word-wrap: break-word;
  max-width: 15vw;
}

h1 > * {
  margin-right: 10px;
}