.alert__content {
  font-size: 0.80rem;

  > p > b {
    margin-right: 4px;
  }
}

.alert-count-box {
  text-align: center;
  position: relative;
  top: calc(45% - 30px);

  * {
    margin-left: auto;
    margin-right: auto;
  }

  > div {
    min-width: 60px;
    min-height: 60px;

    > b {
      color: #ff4861;
      font-weight: 700;
      font-size: 1.2rem;
    }
  }
}

.notification-border-box {
  border-top-width: 2px!important;
}

@media only screen and (max-width: 991px){
  .notification-box h3 {
    text-align: center
  }
}

@media only screen and (min-width: 992px){
  .notification-box h3 {
    margin-top: 1.5rem;
  }
}