.gauge-value-box {
  text-align: center;

  .card__title {
    margin-bottom: 10px;
  }
}
.gauge-value-box-chart {
  position: relative;
}

.gauge-value-box-chart-info {
  position: absolute;
  width: 100%;
  top: calc(50% - 55px);
  animation: label 1.5s ease-in;

  p {
    margin: 0;
  }
}

.gauge-value-box-chart-number {
  font-size: 56px;
  line-height: 60px;

  @include themify($themes) {
    color: themed("colorFitness");
  }
}

.gauge-value-box-chart-units {
  color: $color-additional;
  margin-top: 5px;
}
