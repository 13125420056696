.pressureField {
  margin-left: 18px;
  width: calc(100% - 18px);
  @media only screen and (max-width: 1200px) {
    min-width: calc(50% - 18px);
  }
  @media only screen and (max-width: 768px) {
    min-width: calc(100% - 18px);
  }

  .form-group {
    padding-left: 5px;
  }
}

.pressureCardRow > .expand {
  margin-left: 15px;
  margin-right: 15px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
