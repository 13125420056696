.ArtificialIntelligenceConstants {
  margin-top: 6px;

  .form-group {
    float: left;
  }

  .text-field {
    min-width: 150px;
    max-width: 220px;
  }

  .col {
    min-width: 170px;
    max-width: 240px;
  }

  .expand {
    height: calc(1.1875em + 30px);
    padding: 0 50px;
    margin-top: 15px;
    margin-bottom: 1rem;
    float: right;
  }
}

@media only screen and (min-width: 1520px) {
  .ArtificialIntelligenceConstants {
    > fieldset > .row {
      width: 85%;
      float: left;
    }
  }
}

@media only screen and (max-width: 900px) {
  .ArtificialIntelligenceConstants {
    .form-group {
      float: unset;
    }

    .text-field,
    .col {
      width: 100%;
      flex-basis: unset;
      min-width: unset;
      max-width: unset;
    }
  }

  .artificialIntelligenceEditButton {
    width: 100%;

    > .expand {
      height: 50px;
      max-width: 100%;
      width: 100%;
    }
  }
}
