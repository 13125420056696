.notification {
  max-width: 400px;
  width: calc(100% - 50px);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
  background: white;
  padding: 20px 40px 30px 25px;
  position: relative;
  margin: 10px 25px;

  &.notification--image {
    overflow: hidden;
  }

  &.notification--full-wide {
    max-width: 100vw;
    width: 100vw;
    margin: 0;
    padding: 20px 40px 20px 25px;
  }

  &.notification--primary {
    background: $color-blue;

    .notification__message, .notification__title, .notification__image {
      color: white;
    }
  }

  &.notification--success {
    background: $color-accent;

    .notification__message, .notification__title, .notification__image {
      color: white;
    }
  }

  &.notification--warning {
    background: $color-yellow;

    .notification__message, .notification__title, .notification__image {
      color: white;
    }
  }

  &.notification--danger {
    background: $color-red;

    .notification__message, .notification__title, .notification__image {
      color: white;
    }
  }

  &.notification--black {
    background: $color-black;

    .notification__message, .notification__title, .notification__image {
      color: white;
    }
  }
}

.notification__message {
  margin-top: 0;
  font-size: 12px;
  color: $color-additional;
}

.notification__title {
  margin-bottom: 8px;
  color: #646777;
}

.notification__image {
  position: absolute;
  height: 30px;
  width: 30px;
  top: 25px;
  left: 25px;

  & ~ * {
    padding-left: 60px;
    padding-right: 20px;
  }

  svg {
    display: block;
    margin: auto;
    text-align: center;
    width: 34px;
    height: 34px;
  }
}

.sidebar.sidebar--collapse + .container__wrap {

  .notifications-tl, .notifications-bl {
    left: 0 !important;
  }
}

.rc-notification-notice-close{
  color:white;
  opacity: 0.8;
}