.NotificationsHistoryDateTimeRangeSelector {
  .card-body {
    padding-top: 5px;
    padding-bottom: 20px;
  }
}

.NotificationsHistoryCard {
  .MuiTableBody-root {
    > *:nth-child(2n) {
      background-color: #ebebeb;
    }
  }

  .SeverityCell {
    padding: 5px 20px;
    border-radius: 15px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    width: 150px;

    &.Information {
      color: rgb(12, 84, 96);
      background-color: rgb(112, 187, 253);
    }

    &.Warning {
      color: rgb(133, 100, 4);
      background-color: rgb(238, 205, 74);
    }

    &.Error {
      color: rgb(114, 28, 36);
      background-color: rgb(255, 72, 97);
    }
  }
}
