.SettingsHeader {
    .card-body {
      padding: 15px;
  
      .row {
        margin-left: 0;
        margin-right: 0;
  
        > h2 {
          > span {
            color: #4caf50;
            margin-right: 10px;
            display: inline-block;
          }
          
          margin-top: 5px;
        }
  
        .expand {
          margin-left: auto;
          margin-bottom: 0;
          padding-left: 20px;
          padding-right: 20px;
  
          @media only screen and (max-width: 667px) {
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
  }
  