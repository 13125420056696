@import "../settings/variable.scss";

.error-page {
  .card-body {
    text-align: center !important;
    height: 87vh;
    padding-top: 37vh;
  }

  .card-title {
    color: $color-accent;
    font-size: -webkit-xxx-large;
  }

  .card-title > b {
    font-size: larger;
  }

  .card-subtitle {
    font-weight: bold;
    font-size: large;
  }

  .card-text {
    font-style: italic;
    font-size: small;
  }
}
