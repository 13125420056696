.bacnetObjectsHeader {
  .card-body {
    .row {
      > h2 {
        > span {
          color: #4caf50;
        }
      }
    }
  }
}

.waitingForValues {
  margin-top: 100px;
}

.selectedObjects .card .card-body table thead {
  background-color: white;
}

.selectedObjects .card .MuiTableBody-root {
  > *:nth-child(2n) {
    background-color: white;
  }
}

.objectValues table thead {
  background-color: #f3f4f8;
}

.objectValues .MuiTableBody-root {
  > *:nth-child(2n) {
    background-color: #f3f4f8;
  }
}