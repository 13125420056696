.text-field {
  margin: 1rem 0 !important;

  > label {
    @include themify($themes) {
      color: themed('colorText')
    }
  }
}

// Styles preference hack without other js logic
.text-field:not([class='']) {
  ::after {
    border-bottom-color: $color-accent
  }
}