.settings-form {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  fieldset {
    width: 75%;
    min-width: 200px;

    > .row {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .button-wrapper {
    vertical-align: bottom;
    bottom: 35px;
    position: absolute;
    left: 0;
    right: 0;
  }
}

.icon-container {
  color: #888;
  text-align: center;

  svg {
    width: 100%;
    height: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h3 {
    text-align: center;
  }
}

.radial-menu-select {
  text-align: center;
  height: 100%;

  > h3 {
    text-align: start;
    text-align: left;
  }
}

.radial-menu-select-selector-wrapper {
  margin-left: auto;
  margin-right: auto;
  > div {
    position: static!important;
    z-index: 0!important;
  }
}

.clickable {
  display: block !important;
  cursor: pointer;
}

.settings-field-icon {
  width: 10%;
}

.settings-field-input {
  margin-left: 10px;
  width: calc(90% - 10px);
}
