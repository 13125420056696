$crosshatch-size: 5px;

.schedule-type-button {
  font-size: 13px;
  color: black;
}

.rounded {
  border-radius: 10px !important;
}

$cellsPerHour: 4;
$timeCellCount: 24 * $cellsPerHour;
$separatorAtCell: 4 * $cellsPerHour;

.r-calendar-table {
  display: grid;
  min-width: 90rem;
  grid-template-columns: 15rem repeat($timeCellCount, 1fr);

  @for $i from 1 through 10 {
    &.r-calendar-table-#{$i} {
      grid-template-rows: 2rem repeat($i, 2rem 0.4rem);
    }
  }
}

.row-header {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  padding: 0 5px;

  &.is-clickable {
    cursor: pointer;
  }
}

@for $i from 1 through 10 {
  .row-#{$i} {
    grid-row: $i * 2;
  }

  .header-row-separator-#{$i} {
    grid-row: $i * 2 + 1;

    grid-column-start: 1;
    grid-column-end: 1;

    background-color: #ddd;
  }

  .table-row-separator-#{$i} {
    grid-row: $i * 2 + 1;

    grid-column-start: 2;
    grid-column-end: 98;
    border-left: 2px solid black;

    background-color: #ddd;
  }
}

.cell {
  border-top: 1px solid black;
  border-bottom: 1px solid black;

  &:hover {
    background-color: #cccc;
  }

  &.selecting {
    background-color: transparentize($color-accent, 0.8);
  }
}

@for $e from 1 through $timeCellCount {
  .cell-#{$e} {
    grid-column-start: $e + 1;
    grid-column-end: $e + 1;

    @if ($e == 1) {
      border-left: 2px solid black;
    } @else if ($e == 96) {
      border-right: 1px solid black;
    } @else if (($e - 1) % 16 == 0) {
      border-left: 1px solid black;
    }
  }

  .interval-start-#{$e} {
    @if ($e == 1) {
      border-left: 2px solid black;
    } @else if (($e - 1) % 16 == 0) {
      border-left: 1px solid black;
    }
  }
}

.interval-end-96 {
  border-right: 1px solid black;
}

$breakpoints: 0, 4, 8, 12, 16, 20;

.hour {
  border-left: 1px solid black;
  padding: 0.2rem;
  align-items: flex-end;
  display: flex;

  @each $i in $breakpoints {
    &.hour-#{$i} {
      $columnStart: 2 + ($i * 4);

      grid-column-start: $columnStart;
      grid-column-end: $columnStart + 16;

      @if ($i == 0) {
        border-left: 2px solid black;
      }
    }
  }
}

.interval {
  cursor: pointer;
  height: 2rem;
  min-height: 2rem;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.interval,
.schedule-type-button {
  &.background {
    &--light {
      background: $color-schedule-type-light;

      &.not-saved {
        background: repeating-linear-gradient(
          -45deg,
          transparent,
          transparent $crosshatch-size,
          $color-schedule-type-light $crosshatch-size,
          $color-schedule-type-light $crosshatch-size * 2
        );
      }
    }

    &--heating {
      background: $color-schedule-type-heating;

      &.not-saved {
        background: repeating-linear-gradient(
          -45deg,
          transparent,
          transparent $crosshatch-size,
          $color-schedule-type-heating $crosshatch-size,
          $color-schedule-type-heating $crosshatch-size * 2
        );
      }
    }

    &--door-lock {
      background: $color-schedule-type-door-lock;

      &.not-saved {
        background: repeating-linear-gradient(
          -45deg,
          transparent,
          transparent $crosshatch-size,
          $color-schedule-type-door-lock $crosshatch-size,
          $color-schedule-type-door-lock $crosshatch-size * 2
        );
      }
    }

    &--summary {
      background: $color-schedule-summary-interval;

      &.not-saved {
        background: repeating-linear-gradient(
          -45deg,
          transparent,
          transparent $crosshatch-size,
          $color-schedule-summary-interval $crosshatch-size,
          $color-schedule-summary-interval $crosshatch-size * 2
        );
      }
    }
  }
}
