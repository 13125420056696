.accessCell {
  padding: 5px 20px;
  border-radius: 15px;
  display: inline-block;
  font-weight: 500;
  color: #444 !important;
}

.userAccessForm {
  .material-table {
    tbody {
      .material-table__row:hover,
      .material-table__row[aria-checked="true"] {
        .clickable {
          display: unset;
        }
      }

      tr:not(.material-table__row) {
        height: 25px !important;
      }
    }
  }
}
