.confirmation-button-toolbar:last-child {
  margin-bottom: -15px;

  .btn {
    margin-bottom: 0;
    margin-right: 0;
    width: 100px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    @include minSize("sm") {
      &:not(:last-child) {
        margin-bottom: 0;
        margin-right: 15px;
      }
      & {
        margin-bottom: 0;
      }
    }
  }
}
