.col-fifth {
  position: relative!important;
  width: 100%!important;
  max-width: 20%!important;
  padding-right: 15px!important;
  padding-left: 15px!important;
}

.offset-fifth {
  margin-left: 20%!important;
}

@media (min-width: 576px) {
  .col-sm-fifth {
    position: relative!important;
    max-width: 20%!important;
    width: 100%!important;
    padding-right: 15px!important;
    padding-left: 15px!important;
  }

  .offset-sm-fifth {
    margin-left: 20%!important;
  }
}

@media (min-width: 768px) {
  .col-md-fifth {
    position: relative!important;
    max-width: 20%!important;
    width: 100%!important;
    padding-right: 15px!important;
    padding-left: 15px!important;
  }

  .offset-md-fifth {
    margin-left: 20%!important;
  }
}

@media (min-width: 992px) {
  .col-lg-fifth {
    position: relative!important;
    max-width: 20%!important;
    width: 100%!important;
    padding-right: 15px!important;
    padding-left: 15px!important;
  }

  .offset-lg-fifth {
    margin-left: 20%!important;
  }
}

@media (min-width: 1200px) {
  .col-xl-fifth {
    position: relative!important;
    max-width: 20%!important;
    width: 100%!important;
    padding-right: 15px!important;
    padding-left: 15px!important;
  }

  .offset-xl-fifth {
    margin-left: 20%!important;
  }
}

@media (min-width: 1400px) {
  .col-xxl-fifth {
    position: relative!important;
    max-width: 20%!important;
    width: 100%!important;
    padding-right: 15px!important;
    padding-left: 15px!important;
  }

  .offset-xxl-fifth {
    margin-left: 20%!important;
  }
}

.col-two-fifths {
  position: relative!important;
  width: 100%!important;
  max-width: 40%!important;
  padding-right: 15px!important;
  padding-left: 15px!important;
}

.offset-two-fifths {
  margin-left: 40%!important;
}

@media (min-width: 576px) {
  .col-sm-two-fifths {
    position: relative!important;
    max-width: 40%!important;
    width: 100%!important;
    padding-right: 15px!important;
    padding-left: 15px!important;
  }

  .offset-sm-two-fifths {
    margin-left: 40%!important;
  }
}

@media (min-width: 768px) {
  .col-md-two-fifths {
    position: relative!important;
    max-width: 40%!important;
    width: 100%!important;
    padding-right: 15px!important;
    padding-left: 15px!important;
  }

  .offset-md-two-fifths {
    margin-left: 40%!important;
  }
}

@media (min-width: 992px) {
  .col-lg-two-fifths {
    position: relative!important;
    max-width: 40%!important;
    width: 100%!important;
    padding-right: 15px!important;
    padding-left: 15px!important;
  }

  .offset-lg-two-fifths {
    margin-left: 40%!important;
  }
}

@media (min-width: 1200px) {
  .col-xl-two-fifths {
    position: relative!important;
    max-width: 40%!important;
    width: 100%!important;
    padding-right: 15px!important;
    padding-left: 15px!important;
  }

  .offset-xl-two-fifths {
    margin-left: 40%!important;
  }
}

@media (min-width: 1400px) {
  .col-xxl-two-fifths {
    position: relative!important;
    max-width: 40%!important;
    width: 100%!important;
    padding-right: 15px!important;
    padding-left: 15px!important;
  }

  .offset-xxl-two-fifths {
    margin-left: 40%!important;
  }
}
